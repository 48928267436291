<template>
  <main>
    <ChoiceCard :enabled="false" />
    <CardSeparator />
    <PlanCard
      :enabled="true"
      :app="app"
      :addon="addon"
      :loadingOperation="loadingOperation"
      :selectedAddonProvider="selectedAddonProvider"
      :c3Customer="c3Customer"
      :user="user"
      @addonPlanSelected="(e) => $emit('addonPlanSelected', e)"
    />
    <CardSeparator />
    <ValidationCard :enabled="false" />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import ChoiceCard from "@/components/parts/app/newAddons/ChoiceCard.vue";
import PlanCard from "@/components/parts/app/newAddons/PlanCard.vue";
import ValidationCard from "@/components/parts/app/newAddons/ValidationCard.vue";
import { RemoteOperation } from "@/lib/store/remote-operation";

export default defineComponent({
  name: "AddonsEditionStep2",
  components: {
    ValidationCard,
    PlanCard,
    ChoiceCard,
    CardSeparator,
  },
  props: {
    app: Object,
    loadingOperation: RemoteOperation,
    addon: Object,
    selectedAddonProvider: Object,
    c3Customer: {
      type: Boolean,
      default: true,
    },
    user: Object,
  },
  emits: ["addonPlanSelected"],
  data() {
    return {};
  },
});
</script>
