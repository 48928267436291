<template>
  <OnClickOutside @trigger="hide">
    <label
      v-if="$slots.label"
      class="block text-sm font-medium leading-5 text-scale-10 mb-3 h-6"
    >
      <slot name="label" />
      <span v-if="required" class="text-secondary-3 align-super">
        ({{ $t("required") }})
      </span>
    </label>
    <div class="relative">
      <span class="inline-block w-full rounded shadow-sm">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="relative special-height w-full rounded border border-scale-2 bg-scale-0 pl-3 pr-10 py-2 text-left focus:outline-none focus:border-color-3 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          :class="{
            'cursor-default': !disabled,
            'cursor-not-allowed': disabled,
          }"
          @click="toggleOptionsDropdown()"
        >
          <div class="flex items-center space-x-3">
            <template v-if="disabled">
              <span class="text-scale-4">
                <slot name="disabledPrompt">
                  {{ $t("disabledPrompt") }}
                </slot>
              </span>
            </template>
            <template v-else-if="!searchInProgress && optionsAreLoading">
              <slot name="optionsAreLoading">
                {{ $t("loading") }}
              </slot>
            </template>
            <template v-else-if="searchable && displayOptions">
              <input
                ref="searchInput"
                v-model="search"
                autocomplete="off"
                class="bg-scale-0 block w-full pr-10 text-scale-10 placeholder-scale-5 focus:outline-none"
                placeholder="Search..."
                @click.prevent.stop
              />
            </template>
            <template v-else>
              <template v-if="selectedOption">
                <template v-if="$slots.picture">
                  <span class="flex-shrink-0 h-5 w-5">
                    <slot name="picture" :option="selectedOption" />
                  </span>
                </template>
                <template v-if="$slots.text">
                  <span
                    class="selected-option block truncate"
                    :class="{ humanize: isHumanized(selectedOption) }"
                  >
                    <slot name="text" :option="selectedOption" />
                  </span>
                </template>
              </template>
              <template v-else>
                <slot name="prompt">
                  {{ $t("prompt") }}
                </slot>
              </template>
            </template>
          </div>
          <template v-if="!searchInProgress && optionsAreLoading">
            <span
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <Spinner class="text-scale-5" />
            </span>
          </template>
          <template v-else>
            <span
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <svg
                class="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
        </button>
      </span>

      <transition
        leaveActiveClass="transition ease-in duration-100"
        leaveClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <div
          v-if="displayOptions"
          class="absolute z-40 mt-1 w-full rounded-md bg-scale-0 shadow-lg"
        >
          <div v-if="searchInProgress && optionsAreLoading">
            <ul
              tabindex="-1"
              role="listbox"
              class="max-h-56 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              <li
                role="option"
                class="text-scale-10 cursor-default select-none relative py-2 pl-3 pr-9"
              >
                <div class="flex items-center space-x-3">
                  <slot name="loading">
                    {{ $t("loading") }}
                  </slot>
                </div>
              </li>
            </ul>
          </div>
          <div v-else-if="!optionsAreLoading">
            <ul
              tabindex="-1"
              role="listbox"
              class="max-h-56 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              <!--
              Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

              Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
            -->
              <li
                v-if="filteredOptions.length === 0"
                role="option"
                class="text-scale-10 cursor-default select-none relative py-2 pl-3 pr-9"
              >
                <div class="flex items-center space-x-3">
                  <slot name="noResults">
                    {{ $t("noResults") }}
                  </slot>
                </div>
              </li>

              <li
                v-for="(option, i) in filteredOptions"
                v-else
                id="`listbox-item-${i}`"
                :key="i"
                role="option"
                class="cursor-default select-none relative py-2 pl-3 pr-9"
                :class="{
                  'text-scale-0 bg-indigo-600': isHighlighted(option),
                  'text-scale-10': !isHighlighted(option),
                }"
                @mouseleave="exit()"
                @mousemove="highlight(option)"
                @click="optionSelected(option)"
              >
                <div class="flex items-center space-x-3">
                  <template v-if="$slots.picture">
                    <span class="flex-shrink-0 h-6 w-6">
                      <slot name="picture" :option="option" />
                    </span>
                  </template>

                  <span
                    v-if="$slots.text"
                    class="block truncate"
                    :class="{
                      'font-medium': isSelected(option),
                      'font-normal': !isSelected(option),
                      humanize: isHumanized(option),
                    }"
                  >
                    <slot name="text" :option="option" />
                  </span>
                </div>

                <!--
                Checkmark, only display for selected option.

                Highlighted: "text-white", Not Highlighted: "text-indigo-600"
              -->
                <span
                  v-if="isSelected(option)"
                  class="absolute inset-y-0 right-0 flex items-center pr-4"
                  :class="{
                    'text-scale-0': isHighlighted(option),
                    'text-primary-2': !isHighlighted(option),
                  }"
                >
                  <SelectedGlyph />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
    <p v-if="errors.length > 0" class="mt-2 text-xs text-color-2 mb-4 h-2">
      {{ errors[0] }}
    </p>
  </OnClickOutside>
</template>

<script>
import { OnClickOutside } from "@vueuse/components";
import { debounce } from "lodash";
import { defineComponent } from "vue";

import SelectedGlyph from "@/components/atoms/glyphs/SelectedGlyph.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";

export default defineComponent({
  name: "AdvancedSelectInput",
  components: { OnClickOutside, Spinner, SelectedGlyph },
  props: {
    options: Array,
    optionsAreLoading: Boolean,
    modelValue: [Number, String],
    searchable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue", "search"],
  data() {
    return {
      search: "",
      highlighted: null,
      displayOptions: false,
    };
  },
  computed: {
    filteredOptions() {
      if (this.searchable) {
        const search = this.search.toLowerCase();

        return this.options.filter((option) => {
          return option.text.toLowerCase().indexOf(search) > -1;
        });
      } else {
        return this.options;
      }
    },
    selectedOption() {
      return this.options.find((e) => e.value == this.modelValue);
    },

    searchInProgress() {
      return this.search !== "";
    },
  },
  watch: {
    search: debounce(function (newValue) {
      this.$emit("search", newValue);
    }, 500),
    optionsAreLoading(newValue) {
      if (this.searchable && !newValue) {
        this.$nextTick(() => this.$refs.searchInput?.focus());
      }
    },
  },
  methods: {
    toggleOptionsDropdown() {
      if (this.optionsAreLoading || this.disabled) {
        return;
      }

      if (this.displayOptions) {
        this.displayOptions = false;
        document.removeEventListener("keydown", this.keyboardNavigation);
      } else {
        this.displayOptions = true;
        document.addEventListener("keydown", this.keyboardNavigation);

        if (this.searchable) {
          this.$nextTick(() => this.$refs.searchInput.focus());
        }
      }
    },
    isFirst(option) {
      return this.options[0] === option;
    },
    isLast(option) {
      return this.options[this.options.length - 1] === option;
    },
    isSelected(option) {
      return option.value === this.modelValue;
    },
    isHighlighted(option) {
      if (!option) return false;
      if (!this.highlighted) return false;

      return this.highlighted?.value === option.value;
    },
    isHumanized(option) {
      return option.humanize === true;
    },
    highlight(option) {
      this.highlighted = option;
    },
    optionSelected(option) {
      this.$emit("update:modelValue", option.value);
      this.toggleOptionsDropdown();
    },
    exit() {
      this.highlighted = null;
    },
    hide(e) {
      if (this.displayOptions) {
        // Hack/workaround : the click-away is sometimes triggered from this
        // node when it should not. It results in the listing being closed
        // as soon as it is opened. Note sure why it happens, but this fixes it.
        // Note: the `target` is out of the DOM by the time this handler is invoked,
        // so we have to rely on attributes set on
        if (e?.target?.classList.contains("selected-option")) {
          return;
        }

        this.toggleOptionsDropdown();
      }
    },
    keyboardNavigation(e) {
      switch (e.key) {
        case "ArrowDown":
          if (this.filteredOptions.length === 0) return;

          if (this.highlighted === null) {
            this.highlight(this.filteredOptions[0]);
          } else if (!this.isLast(this.highlighted)) {
            const index = this.filteredOptions.indexOf(this.highlighted);
            this.highlight(this.filteredOptions[index + 1]);
          }

          e.preventDefault();
          break;
        case "ArrowUp":
          if (this.filteredOptions.length === 0) return;

          if (!this.isFirst(this.highlighted)) {
            const index = this.filteredOptions.indexOf(this.highlighted);
            this.highlight(this.filteredOptions[index - 1]);
          }
          e.preventDefault();
          break;
        case "Enter":
          if (this.filteredOptions.length === 0) return;

          if (this.highlighted) {
            this.optionSelected(this.highlighted);
          }
          e.preventDefault();
          break;
      }
    },
  },
});
</script>

<style>
.special-height {
  height: 42px;
}
</style>

<i18n>
en:
  loading: "Loading in progress..."
  prompt: "Select an option"
  disabledPrompt: "Disabled"
  noResults: "No results."
  required: "required"
fr:
  loading: "Loading in progress..."
  prompt: "Select an option"
  disabledPrompt: "Disabled"
  noResults: "No results."
  required: "requis"
</i18n>
