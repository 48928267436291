<template>
  <div v-for="(app, index) in filteredApps" :key="index">
    <div class="mt-4">
      <div
        class="rounded-sm border-b border-scale-3 px-4 py-3 bg-scale-1 font-medium flex items-center justify-between space-x-1 cursor-pointer"
        @click="open[index] = !open[index]"
      >
        <div class="flex items-center space-x-1">
          <font-awesome-icon
            size="2x"
            :icon="open[index] ? 'caret-down' : 'caret-right'"
            class="h-5 w-5 -ml-2"
          />
          <span>
            {{ app.name }}
          </span>
          <font-awesome-icon
            class="h-3.5 px-1"
            icon="external-link-alt"
            :title="$t('dashboardLink')"
            @click="openAppCollaborators(app)"
          />
        </div>
        <div class="text-scale-10 font-medium text-base">
          {{ collaboratorCount(app) }}
        </div>
      </div>
      <div v-if="open[index]">
        <SCTable
          layout="auto"
          :headers="tableHeaders"
          :items="app.collaborators"
          :itemName="$t('itemName')"
          :shadowed="false"
        >
          <template #item.avatar="{ item }">
            <div class="flex place-items-center items-center flex-grow">
              <CollaboratorPortrait
                :item="collaboratorPortraitItem(item)"
              ></CollaboratorPortrait>
            </div>
          </template>
          <template #item.username="{ item }">
            <span :class="{ 'text-scale-5': item.status === 'pending' }">
              {{ item.username }}
            </span>
          </template>
          <template #item.email="{ item }">
            <span :class="{ 'text-scale-5': item.status === 'pending' }">
              {{ item.email }}
            </span>
          </template>
          <template #item.status="{ item }">
            <span :class="{ 'text-scale-5': item.status === 'pending' }">
              {{ item.status }}
            </span>
          </template>
        </SCTable>
      </div>
    </div>
  </div>
  <div class="flex mt-4 items-center">
    <div class="w-1/3 flex place-items-center">
      <div class="text-scale-5 text-sm">
        <span class="text-scale-5 text-sm font-sans">{{
          $tc("app", {
            count: filteredApps.length,
          })
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCTable from "@/components/organisms/tables/SCTable.vue";
import CollaboratorPortrait from "@/components/parts/appSettings/collaboratorsSettings/CollaboratorPortrait.vue";

export default defineComponent({
  name: "AppCollaborators",
  components: {
    CollaboratorPortrait,
    SCTable,
  },
  props: {
    filteredApps: {
      type: Object,
      required: true,
    },
  },
  emits: ["openAppCollaborators"],
  data() {
    return {
      open: [],
      tableHeaders: [
        { text: "", value: "avatar", class: "w-14" },
        {
          text: this.$t("table.headers.username"),
          value: "username",
        },
        {
          text: this.$t("table.headers.email"),
          value: "email",
          class: "hidden lg:block h-8 pt-1.5",
          itemsClass: "hidden lg:block h-12 pt-3",
        },
        {
          text: this.$t("table.headers.status"),
          value: "status",
        },
      ],
    };
  },
  methods: {
    collaboratorPortraitItem(collaborator) {
      return {
        username: collaborator.username,
        status: collaborator.status,
        email: collaborator.email,
      };
    },
    openAppCollaborators(item) {
      this.$emit("openAppCollaborators", item);
    },
    collaboratorCount(app) {
      const count = app.collaborators.length;
      const label = this.$t(count === 1 ? "collaborator" : "collaborators");
      return `${count} ${label}`;
    },
  },
});
</script>

<i18n>
en:
  table:
    headers:
      username: "Username"
      email: "Email"
      status: "Status"
  collaborator: "collaborator"
  collaborators: "collaborators"
  pending: "Pending invitation"
  accepted: "Invitation accepted"
  dashboardLink: "Open app dashboard"
  itemName: "collaborator"
  app: "0 app | 1 app | {count} apps"

fr:
  table:
    headers:
      username: "Nom d'utilisateur"
      email: "Email"
      status: "Statut"
  collaborator: "collaborateur"
  collaborators: "collaborateurs"
  pending: "Invitation en attente"
  accepted: "Invitation acceptée"
  dashboardLink: "Ouvrir le dashboard de l'app"
  itemName: "collaborateur"
  app: "0 app | 1 app | {count} apps"

</i18n>
