<template>
  <div>
    <label
      :class="labelClass"
      class="block text-sm font-medium leading-5 text-scale-10 mb-3"
    >
      {{ label }}
    </label>
    <SelectInputAtom
      v-model="inputValue"
      :state="state"
      :options="options"
    ></SelectInputAtom>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SelectInputAtom from "@/components/atoms/inputs/SelectInput.vue";

export default defineComponent({
  name: "SelectInput",
  components: { SelectInputAtom },
  props: {
    options: Array,
    label: String,
    state: String,
    labelClass: String,
    modelValue: String,
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>
