export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "table": {
          "headers": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
          }
        },
        "collaborator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborator"])},
        "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborators"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending invitation"])},
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation accepted"])},
        "dashboardLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open app dashboard"])},
        "itemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborator"])},
        "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 app"]), _normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])}
      },
      "fr": {
        "table": {
          "headers": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
          }
        },
        "collaborator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborateur"])},
        "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborateurs"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation en attente"])},
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation acceptée"])},
        "dashboardLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le dashboard de l'app"])},
        "itemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborateur"])},
        "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 app"]), _normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])}
      }
    }
  })
}
