<template>
  <div class="flex flex-row justify-between mb-6 rounded-sm">
    <div class="flex flex-row space-x-6 items-center">
      <div class="flex flex-row items-center space-x-2">
        <span class="text-sm text-scale-7 whitespace-nowrap">{{
          $t("groupBy")
        }}</span>
        <GroupBySelector
          :selectedValue="groupBy"
          @groupBySelected="$emit('groupBySelected', $event)"
        />
      </div>
      <div class="relative border border-scale-2 rounded w-full max-w-xs">
        <input
          :value="searchTerm"
          class="form-input bg-scale-0 rounded block w-full pr-10 sm:text-sm sm:leading-5 placeholder-scale-6 px-3 py-1 focus:ring-1 focus:ring-color-3 focus:outline-none text-scale-6"
          :placeholder="this.searchPlaceholder()"
          @input="$emit('update:searchTerm', $event.target.value)"
        />
        <SearchGlyph />
      </div>
    </div>
    <div class="flex items-center">
      <ExportButton
        :exportItems="itemsToExport"
        :exportFilenamePrefix="exportFilenamePrefix"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SearchGlyph from "@/components/atoms/glyphs/SearchGlyph.vue";
import ExportButton from "@/components/organisms/buttons/ExportButton.vue";
import GroupBySelector from "@/components/parts/collaborators/GroupBySelector.vue";

export default defineComponent({
  name: "Toolbar",
  components: {
    GroupBySelector,
    ExportButton,
    SearchGlyph,
  },
  props: {
    groupBy: String,
    searchTerm: String,
    itemsToExport: Array,
    exportFilenamePrefix: String,
  },
  emits: ["groupBySelected", "update:searchTerm"],
  methods: {
    searchPlaceholder() {
      return `${this.$t("search_by")} ${this.$t(this.groupBy).toLowerCase()}`;
    },
  },
});
</script>

<i18n>
en:
  groupBy: "Group by"
  collaborators: "Collaborators"
  applications: "Applications"
  search_by: "Search by"
fr:
  groupBy: "Grouper par"
  collaborators: "Collaborateurs"
  applications: "Applications"
  search_by: "Rechercher par"
</i18n>
