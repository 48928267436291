<template>
  <main>
    <div class="mt-8">
      <Card>
        <template v-slot:header>
          <CardHeader
            :title="$t('title')"
            :subtitle="$t('subtitle')"
          ></CardHeader>
        </template>
        <template v-slot:body>
          <template v-if="fetchPromiseInfo.isLoading">
            <LoadingCardState />
          </template>

          <template v-else>
            <div v-if="collaborators.length">
              <Toolbar
                :groupBy="groupBy"
                :searchTerm="searchTerm"
                :itemsToExport="exportItems()"
                :exportFilenamePrefix="exportFilenamePrefix"
                @groupBySelected="handleGroupBySelected"
                @update:searchTerm="search"
              />
              <CollaboratorTable
                v-if="groupBy === 'collaborators'"
                :filteredCollaborators="filteredCollaborators"
                @openAppCollaborators="openAppCollaborators"
              />
              <AppCollaborators
                v-else
                :filteredApps="filteredApps"
                @openAppCollaborators="openAppCollaborators"
              />
            </div>
            <div v-else>
              <NoCollaborators :isAppOwner="isAppOwner" />
            </div>
          </template>
        </template>
        <template v-slot:footer>
          <CardFooter :withViewAllButton="false"></CardFooter>
        </template>
      </Card>

      <ProTipAlert class="mt-4 flex">
        <div>{{ $t("proTip") }}</div>
      </ProTipAlert>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import NoCollaborators from "@/components/views/collaborators/NoCollaborators.vue";

import AppCollaborators from "../parts/collaborators/AppCollaborators.vue";
import CollaboratorTable from "../parts/collaborators/CollaboratorTable.vue";
import Toolbar from "../parts/collaborators/Toolbar.vue";

export default defineComponent({
  name: "Collaborators",
  components: {
    LoadingCardState,
    CardFooter,
    CardHeader,
    Card,
    NoCollaborators,
    ProTipAlert,
    Toolbar,
    AppCollaborators,
    CollaboratorTable,
  },
  props: {
    username: {
      type: String,
      default: null,
    },
    collaborators: {
      type: Array,
    },
    fetchPromiseInfo: {
      type: Object,
    },
    isAppOwner: {
      type: Boolean,
      required: true,
    },
    groupBy: {
      type: String,
      default: "collaborators",
    },
  },
  emits: ["openAppCollaborators", "groupBySelected", "update:searchTerm"],
  data: function () {
    return {
      openCollaborator: null,
      searchTerm: "",
    };
  },
  computed: {
    exportFilenamePrefix() {
      return this.username + "_collaborators";
    },
    filteredCollaborators() {
      if (!this.searchTerm) {
        return this.collaborators;
      }

      const lowerSearch = this.searchTerm.toLocaleLowerCase();
      return this.collaborators.filter((collaborator) =>
        this.searchCollaborators(collaborator, lowerSearch),
      );
    },
    filteredApps() {
      const allApps = {};
      let filteredApps = [];

      this.collaborators.forEach((collaborator) => {
        collaborator.apps.forEach((app) => {
          if (!allApps[app.name]) {
            allApps[app.name] = { ...app, collaborators: [] };
          }

          allApps[app.name]["collaborators"].push({ ...collaborator });
        });
      });

      if (this.searchTerm) {
        filteredApps = Object.values(allApps).filter((app) =>
          this.searchApps(app, this.searchTerm),
        );
      } else {
        filteredApps = Object.values(allApps);
      }

      return filteredApps.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    search(value) {
      this.searchTerm = value;
    },
    handleGroupBySelected(groupBy) {
      this.$emit("groupBySelected", groupBy);
      this.searchTerm = "";
    },
    exportItems() {
      return this.collaborators.map((collaborator) => {
        return {
          id: collaborator.id,
          status: collaborator.status,
          user_id: collaborator.id,
          username: collaborator.username,
          email: collaborator.email,
          app_id: collaborator.app_id,
          app_name: collaborator.app_name,
        };
      });
    },
    collaboratorPortraitItem(collaborator) {
      return {
        username: collaborator.username,
        status: collaborator.status,
        email: collaborator.email,
        statusHidden: true,
      };
    },
    searchCollaborators(row, search) {
      const searchLower = search.toLocaleLowerCase();

      return (
        row.email.toLocaleLowerCase().includes(searchLower) ||
        row.username.toLocaleLowerCase().includes(searchLower)
      );
    },
    searchApps(row, search) {
      const searchLower = search.toLocaleLowerCase();

      return row.name.toLocaleLowerCase().includes(searchLower);
    },
    openAppCollaborators(item) {
      this.$emit("openAppCollaborators", item);
    },
  },
});
</script>

<i18n>
en:
  title: "Collaborators of your applications"
  subtitle: "View the list of all collaborators of your applications."
  proTip: "Although this view presents a consolidated list, collaborators are managed individually in each application."

fr:
  title: "Collaborateurs de vos applications"
  subtitle: "Consultez la liste de tous les collaborateurs de vos applications."
  proTip: "Bien que cette vue présente une liste consolidée, la gestion des collaborateurs s’effectue individuellement pour chaque application."
</i18n>
