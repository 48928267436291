<template>
  <StepCard
    :step="2"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <template v-if="enabled && loadingOperation">
      <template v-if="loadingOperation.isLoading">
        <LoadingCardState />
      </template>

      <template v-else-if="loadingOperation.isSuccess">
        <template v-if="!selectedAddonProvider">
          <FormAlert class="mt-4" styling="error">
            <template #text>
              {{ $t("providerNotFound", { addon: $route.params.providerId }) }}
            </template>
          </FormAlert>
          <div class="text-sm text-scale-5 text-right mt-1">
            <a
              class="cursor-pointer"
              :title="$t('changeProviderTitle')"
              @click="$emit('changeAddonProvider')"
            >
              {{ $t("changeProvider") }}
            </a>
          </div>
        </template>
        <template v-else>
          <div
            class="flex mt-4 py-4 bg-scale-0 text-scale-10 border rounded-lg"
          >
            <div class="px-4">
              <AddonProviderIcon :addonProvider="selectedAddonProvider" />
            </div>
            <div class="px-4 flex-1 text-left">
              <div class="font-medium">
                {{ selectedAddonProvider.name }}
              </div>
              <p class="mt-2 flex">
                <span class="flex-grow text-scale-8">
                  {{ selectedAddonProvider.short_description }}
                </span>
              </p>
            </div>
          </div>

          <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
            <form @submit.prevent="handleSubmit(submit)">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="selectedPlanId"
                rules="required"
                name="plan"
              >
                <PlanOptionRow
                  :name="field.name"
                  :modelValue="field.value"
                  :provider="selectedAddonProvider"
                  :options="availablePlans"
                  labelClass="capitalize"
                  :errors="errors"
                  :displayPrices="!app.c3_resource"
                  :user="user"
                  @update:modelValue="handleChange"
                ></PlanOptionRow>
              </Field>
              <div class="flex">
                <SCButton
                  block
                  kind="primary"
                  size="lg"
                  class="mt-8 flex-grow"
                  :disabled="!meta.valid"
                  @click="handleSubmit(submit)"
                >
                  {{ $t("buttonName") }}
                </SCButton>
              </div>

              <div v-if="!addon" class="text-sm text-scale-5 text-right mt-1">
                <a
                  class="cursor-pointer"
                  :title="$t('changeProviderTitle')"
                  @click="$emit('changeAddonProvider')"
                >
                  {{ $t("changeProvider") }}
                </a>
              </div>
            </form>
          </Form>
        </template>
      </template>
    </template>
  </StepCard>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import AddonProviderIcon from "@/components/atoms/graphics/addonProviders/Icon.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import PlanOptionRow from "@/components/parts/app/newAddons/PlanOptionRow.vue";
import { RemoteOperation } from "@/lib/store/remote-operation";

export default defineComponent({
  name: "PlanCard",
  components: {
    AddonProviderIcon,
    StepCard,
    LoadingCardState,
    PlanOptionRow,
    Field,
    Form,
    SCButton,
    FormAlert,
  },
  props: {
    app: Object,
    enabled: Boolean,
    loadingOperation: RemoteOperation,
    addon: Object,
    selectedAddonProvider: Object,
    user: Object,
  },
  emits: ["addonPlanSelected", "changeAddonProvider"],
  data() {
    return {
      selectedPlanId: null,
    };
  },
  computed: {
    availablePlans() {
      if (!this.selectedAddonProvider) {
        return [];
      }

      let { plans } = this.selectedAddonProvider;

      if (this.app.hds_resource) {
        plans = plans.filter((plan) => plan.hds_available);
      }

      if (this.app.c3_resource) {
        plans = plans.filter((plan) => plan.c3_available);
      }

      if (!this.addon) {
        return plans;
      }

      return plans.map((plan) => {
        if (plan.id === this.addon.plan.id) {
          return { ...plan, selected: true };
        } else {
          return plan;
        }
      });
    },
    selectedPlan() {
      return (this.availablePlans || []).find((plan) => {
        return plan.id === this.selectedPlanId;
      });
    },
  },
  watch: {
    addon: {
      handler(newVal) {
        if (newVal) {
          this.selectedPlanId = newVal.plan.id;
        } else {
          this.selectedPlanId = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    submit() {
      this.$emit("addonPlanSelected", this.selectedPlan);
    },
  },
});
</script>

<i18n>
  en:
    title: "Plan"
    subtitle: "Choose the one that fit you best"
    changeProvider: "Change addon"
    changeProviderTitle: "Come back to the previous step to allow you to choose another addon"
    buttonName: "Finish"
    providerNotFound: "Either there is no '{addon}' addon, or it is already added to your app."
  fr:
    title: "Plan"
    subtitle: "Choisissez celui qui vous convient le mieux"
    changeProvider: "Choisir un autre addon"
    changeProviderTitle: "Reviens à l'étape précédente et vous permets de choisir un autre addon"
    buttonName: "Valider"
    providerNotFound: "Either there is no '{addon}' addon, or it is already added to your app."
</i18n>
