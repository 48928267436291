<template>
  <SimpleModal size="w-1/4" @modalClosed="$emit('modalClosed')">
    <template #title>
      {{ $t("title") }}
    </template>

    <template #body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit="(e) => handleSubmit(e, submitForm)">
          <div class="mt-6">
            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.username"
              name="username"
              rules="required"
            >
              <TextInput
                :name="field.name"
                :modelValue="field.value"
                :label="$ta('account', 'username')"
                :errors="errors"
                @update:modelValue="handleChange"
              ></TextInput>
            </Field>

            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.fullname"
              name="fullname"
              rules="required"
            >
              <TextInput
                :name="field.name"
                :modelValue="field.value"
                :label="$ta('account', 'fullname')"
                :errors="errors"
                @update:modelValue="handleChange"
              ></TextInput>
            </Field>

            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.location"
              name="location"
            >
              <TextInput
                :name="field.name"
                :modelValue="field.value"
                :label="$ta('account', 'location')"
                :errors="errors"
                @update:modelValue="handleChange"
              ></TextInput>
            </Field>

            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.company"
              name="company"
            >
              <TextInput
                :name="field.name"
                :modelValue="field.value"
                :label="$ta('account', 'company')"
                :errors="errors"
                @update:modelValue="handleChange"
              ></TextInput>
            </Field>

            <Field
              v-slot="{ field, handleChange }"
              v-model="form.role"
              name="role"
            >
              <SelectInput
                :options="roleOptions"
                :label="$ta('account', 'role')"
                :modelValue="field.value"
                @update:modelValue="handleChange"
              />
            </Field>

            <CheckboxInputAtom
              v-model="form.email_newsletter"
              :label="$ta('account', 'emailNewsletter')"
              class="mt-2"
            />
          </div>

          <div class="flex">
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("action") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Form, Field } from "vee-validate";
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SelectInput from "@/components/molecules/inputs/SelectInput.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "InformationsModal",
  components: {
    SelectInput,
    TextInput,
    CheckboxInputAtom,
    SCButton,
    SimpleModal,
    Form,
    Field,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    availableRoles: Array,
  },
  emits: ["modalClosed"],
  computed: {
    roleOptions() {
      return this.availableRoles.map((role) => ({
        text: this.$ta("account", `roles.${role}`),
        value: role,
      }));
    },
  },
});
</script>

<i18n>
en:
  title: "Edit profile"
  action: "Update"
fr:
  title: "Modifier le profil"
  action: "Mettre a jour"
</i18n>
