<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton :disabled="isEnabling" :loading="isLoading" @click="submit">
          {{ buttonName }}
        </SCButton>
      </template>
    </CardHeader>
    <div>
      <div v-if="isLoading" class="animate-pulse flex space-x-4">
        <div class="h-4 bg-gray-400 rounded w-1/3"></div>
      </div>

      <p v-if="tfa.latestFetch.isSuccess">
        <span class="text-scale-8 mr-1">{{ $t("2FaStatus.label") }}</span>
        <span v-if="tfa?.value?.enabled" class="text-success font-bold">{{
          $t("2FaStatus.enabled")
        }}</span>
        <span v-if="!tfa?.value?.enabled" class="text-secondary-3 font-bold">{{
          $t("2FaStatus.disabled")
        }}</span>
      </p>
      <p v-if="tfa?.value?.enabled" class="text-scale-8 mt-4">
        {{ $t("explanationsWhenEnabled") }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "TwoFactorCard",
  components: {
    CardHeader,
    SCButton,
  },
  props: {
    tfa: Object,
    isEnabling: Boolean,
    initiateOperation: Object,
    disableOperation: Object,
  },
  emits: ["twoFactorDisabled", "twoFactorInitiated"],
  computed: {
    isLoading() {
      const fetch = this.tfa.latestFetch;
      const initiate = this.initiateOperation;
      const disable = this.disableOperation;

      return (
        initiate?.isLoading || disable?.isLoading || fetch?.isLoading || false
      );
    },
    buttonName() {
      if (this.tfa?.value?.enabled === false) {
        return this.$t("buttonNameDisabled");
      }
      return this.$t("buttonNameEnabled");
    },
  },
  methods: {
    submit() {
      if (this.tfa?.value?.enabled) {
        this.$emit("twoFactorDisabled");
      } else {
        this.$emit("twoFactorInitiated");
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Two-factor Authentication (2FA)"
  subtitle: "Add a verification step to protect your account and prevent unauthorized access."
  buttonNameDisabled: "Enable"
  buttonNameEnabled: "Disable"
  2FaStatus:
    label: "Two-factor authentication"
    disabled: "is disabled"
    enabled: "is enabled"
  explanationsWhenEnabled: "Each time you log in, you must confirm your identity using a one-time, temporary password generated by your authentication app."

fr:
  title: "Authentification à deux facteurs (2FA)"
  subtitle: "Ajoutez une étape de vérification pour protéger votre compte et empêcher les accès non autorisés."
  buttonNameDisabled: "Activer"
  buttonNameEnabled: "Désactiver"
  2FaStatus:
    label: "L'authentification à deux facteurs"
    disabled: "est désactivée"
    enabled: "est activée"
  explanationsWhenEnabled: "À chaque connexion, vous devez confirmer votre identité à l’aide d’un mot de passe à usage unique et temporaire, généré par votre application d’authentification."
</i18n>
