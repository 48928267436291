<template>
  <div class="bg-scale-0">
    <div class="container px-4">
      <div class="flex py-4 border-t border-scale-2">
        <PageMenu :items="menuItems"></PageMenu>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PageMenu from "@/components/molecules/menus/PageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ProfileMenu",
  components: { PageMenu },
  props: {
    selection: String,
    user: Object,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems: function () {
      let menuItems = [
        {
          name: this.$t("overview"),
          routerArgs: { name: Routes.Overview },
        },
        {
          name: this.$t("projects"),
          routerArgs: { name: Routes.Projects },
        },
        {
          name: this.$t("collaborators"),
          routerArgs: { name: Routes.Collaborators },
        },
        {
          name: this.$t("consumption"),
          routerArgs: { name: Routes.Consumption },
        },
        {
          name: this.$t("billing"),
          routerArgs: { name: Routes.Billing.Root },
        },
      ];

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.routerArgs.name)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
en:
  overview: "Overview"
  projects: "Apps"
  consumption: "Consumption"
  collaborators: "Collaborators"
  billing: "Billing"
fr:
  overview: "Vue générale"
  projects: "Apps"
  consumption: "Consommation"
  collaborators: "Collaborateurs"
  billing: "Facturation"
</i18n>
